import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import { configureIndexHtml } from './configureIndexHtml'
import website from './site/config'

configureIndexHtml(website)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App website={website} />
  </React.StrictMode>
)