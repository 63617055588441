import { SentenceWithLink } from "./types"

export const getSentenceWithLinkPieces = (sentence: SentenceWithLink) => {
  const pieces = sentence.text.split(sentence.linkText)
  const [sentenceWithLinkPartOne, sentenceWithLinkPartTwo] = pieces || []
  return [sentenceWithLinkPartOne, sentence.linkText, sentenceWithLinkPartTwo]
}

export const formatDate = (date: Date) => date.toLocaleDateString("en-US", {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
})
