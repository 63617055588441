import React, { useMemo, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
} from 'react-router-dom'

import Landing from './views/Landing'
import ItemDetail from './views/ItemDetail'
import ItemList from './views/ItemList'
import ContactForm from './views/forms/ContactForm'
import Widget from './views/Widget'
import TextOnly from './views/TextOnly'

import './App.css'
import { Page, WebSite } from './types'
import StorageForm from './views/forms/StorageForm'

export const separator = <hr style={{ background: "black", height: 1.5 }} />

const App = ({ website }: { website: WebSite }) => {
  const menuOptions = website.pageOrder.map((route: string) => (
    { path: `/${route !== website.config.defaultRoute ? route : ''}`, name: route }
  ))

  const [burguerMenuActive, setBurguerMenuActive] = useState(false)
  const [activeMenu, setActiveMenu] = useState(website.config.defaultRoute)

  const location = useMemo(() => window.location, [window.location])
  const currentPath = useMemo(() => location.pathname, [location.pathname])
  const currentPage = useMemo(() => website.pages[Object.keys(website.pages).find(k => currentPath.endsWith(k))! || 'home'], [currentPath, website.pages])

  const toggleBurguerMenu = (skipIfNotOpen = false) => {
    if (skipIfNotOpen && burguerMenuActive === false) {
      return
    }
    setBurguerMenuActive(!burguerMenuActive)
  }

  const handleMenuNav = (name: string) => {
    toggleBurguerMenu(true)
    if (activeMenu !== name) {
      setActiveMenu(name)
    }
  }

  const getElementForRoute = (page: Page) => {
    switch (page.pageType) {
      case 'landing-social': {
        return <Landing page={page} />
      }
      case 'item-detail': {
        return <ItemDetail page={page} />
      }
      case 'item-list': {
        return <ItemList page={page} />
      }
      case 'text-only': {
        return <TextOnly page={page} />
      }
      case 'contact-form': {
        return <ContactForm page={page} />
      }
      case 'storage-form': {
        return <StorageForm page={page} />
      }
      case 'widget': {
        return <Widget page={page} />
      }
    }
  }

  return (
    <BrowserRouter>
      <div className="App">
        <div className="navbar" aria-label="main-navigation"
          style={{
            background: currentPage?.menu.useBackgroundGradient
              ? `linear-gradient(${currentPage?.gradient?.direction}, ${currentPage?.gradient?.first}, ${currentPage.gradient?.second})`
              : currentPage.menu.backgroundColor
          }}
        >
          <div className="navbar-brand">
            {website.config.logo.brandLogoUri
              ? <img
                className="navbar-item logo"
                src={require(`./site/images/${website.config.logo.brandLogoUri}`)}
                alt={website.config.logo.name}
              />
              : <h1>{website.config.logo.name}</h1>
            }
            <div
              className={`navbar-burger inline ${burguerMenuActive ? 'is-active' : 'inactive'}`}
              style={{ color: 'yellow' }}
              aria-label="menu"
              aria-expanded="false"
              onClick={() => toggleBurguerMenu()}
            >
              <span aria-hidden="true" style={{ height: 2 }} />
              <span aria-hidden="true" style={{ height: 2 }} />
              <span aria-hidden="true" style={{ height: 2 }} />
            </div>
          </div>
          <div className={burguerMenuActive ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className="navbar-end">
              {
                menuOptions.map(option => (
                  // <Link className="navbar-item"
                  //   key={option.path}
                  //   // onClick={() => handleMenuNav(option.name)}
                  //   to={option.path}
                  //   style={currentPage?.menu.useBackgroundGradient ? { background: 'transparent' } : {}}
                  // >
                  <a
                    key={option.name}
                    className="navbar-item is-size-6"
                    style={{ fontWeight: 600, color: currentPage?.menu.color || 'inherit' }}
                    href={option.name.startsWith("(614)")
                      ? "tel:6146992495"
                      : "mailto:hello@gahannarvandboat.com"
                    }
                  >
                    <span className={`navbar-item ${(option.path === '/' && currentPath === 'home') || option.path.endsWith(currentPath) ? 'is-uppercase' : ''}`}>
                      {option.name}
                    </span>
                  </a>
                  // </Link> 
                ))
              }
            </div>
          </div>
        </div>
        <Routes>
          {
            Object.keys(website.pages).map((key) => (
              !website.pages[key].isHidden && (<Route
                key={key}
                path={key === 'home' ? "/" : `/${key}/`}
                element={getElementForRoute(website.pages[key])}
              />)
            ))
          }
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <div className="footer">
          <div className="content has-text-centered is-size-4">
            <div style={{ gap: 30, display: "flex", flexDirection: "row", justifyContent: "space-between", paddingLeft: 40, paddingRight: 40, flexWrap: 'wrap' }}>
              <div className="footer-column">
                <div className="column-title">Business Hours</div>
                {website.config.footer.businessHours.map(({ day, hours }: ({ day: string, hours: string })) => (
                  <div style={{ textAlign: "left" }} className="is-size-6">
                    <span style={{ color: "white" }}>{day} <span style={{ color: "yellow" }}>{hours}</span></span>
                  </div>
                ))}
              </div>
              <div className="footer-column">
                <div className="column-title">Address</div>
                <div className="is-size-6">
                  <a target="_" href="https://www.google.com/maps/place/Gahanna+RV+%26+Boat+Storage/@40.0208334,-82.9056599,16z/data=!3m1!4b1!4m6!3m5!1s0x883861fcb5f3df29:0xeb3a49af8bf7e4c5!8m2!3d40.0208293!4d-82.9030796!16s%2Fg%2F1tf6x_xf?entry=ttu">
                    <div style={{ color: "yellow", textDecoration: "underline" }}>{website.config.footer.address}</div>
                    <div style={{ color: "yellow", textDecoration: "underline" }}>{`${website.config.footer.city}, ${website.config.footer.state} ${website.config.footer.zip}`}</div>
                  </a>
                </div>
              </div>
              <div className="footer-column">
                <div className="column-title">Contact Us</div>
                <div className="is-size-6">
                  <div><a style={{ color: "yellow", textDecoration: "underline" }} href={`mailto:${website.config.footer.email}`}>{website.config.footer.email}</a></div>
                  <div><a style={{ color: "yellow", textDecoration: "underline" }} href={`tel:${website.config.footer.phone}`}>{website.config.footer.phone}</a></div>
                </div>
              </div>
              <div className="footer-column">
                <div style={{ textAlign: "left" }}>
                  <div className="column-title">Social</div>
                  {website.config.footer.social.map((soc: { platformName: string, link: string }) => (
                    <div key={soc.platformName} className="is-size-6">
                      <a style={{ color: "yellow", textDecoration: "underline" }} target="_" href={soc.link}>{soc.platformName}</a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-signature">
            <span>{`© ${(new Date()).getFullYear()} Valentronix `}</span>
            <br />
            <span>Designed & Powered by: <span style={{ fontWeight: 900 }}>Valentronix Storage Solutions</span></span>
          </div>
        </div>
      </div>
    </BrowserRouter >
  )
}

export default App