import { WebSite } from "./types"

const FB_PIXEL_SCRIPT_FUNCTION = `
!function (f, b, e, v, n, t, s) {
 if (f.fbq) return; n = f.fbq = function () {
   n.callMethod ?
     n.callMethod.apply(n, arguments) : n.queue.push(arguments)
 };
 if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
 n.queue = []; t = b.createElement(e); t.async = !0;
 t.src = v; s = b.getElementsByTagName(e)[0];
 s.parentNode.insertBefore(t, s)
}(window, document, 'script',
 'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '@@@PIXEL_ID@@@');
fbq('track', 'PageView');
`

export const configureIndexHtml = (website: WebSite) => {

  const titleTag = document.getElementById('browser-tab-title')
  if (titleTag) {
    titleTag.innerHTML = website.config.browserTabTitle
  }

  if (website.config.facebook) {
    const { metadata, pixel } = website.config.facebook
    Object.entries(metadata)
      .forEach(([key, { attribute, value }]) => {
        document.getElementById(key)?.setAttribute(attribute, value.toString())
      })

    if (pixel) {
      const noScriptTag = document.getElementById("fb:pixel:image")
      if (noScriptTag) {
        noScriptTag.innerHTML = `<img height="1" width="1" style="display:none" src="${pixel.imageSrc}" />`
      }

      const fbScriptTag = document.getElementById("fb:pixel:function")
      if (fbScriptTag) {
        fbScriptTag.innerHTML = FB_PIXEL_SCRIPT_FUNCTION.replace('@@@PIXEL_ID@@@', pixel.id)
      }
    }
  }

  const fontsStyleTag = document.getElementById('font-families')
  if (fontsStyleTag) {
    fontsStyleTag.innerHTML = `
  * {
      font-family: '${website.config.text.fonts.primary.family}';
    }
  `
  }

  document.getElementById("primary-font")?.setAttribute("href", website.config.text.fonts.primary.url)
}