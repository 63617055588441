import React from 'react'
import { PageProperties, Review, ServiceDescription } from '../types'
import './Landing.css'
import { separator } from '../App'

const Landing = ({ page }: PageProperties) => {

  // const separator = <hr style={{ background: "black", height: 1.5 }} />

  return (
    <div
      className="view"
      style={{
        padding: '10%',
        color: page.textColor || page.menu.color,
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}
    >
      <div className={`is-size-1 title ${page.textColor ? "forceBlack" : "forceWhite"}`}>
        {page.title}
      </div>
      <div className={`is-size-4 title ${page.textColor ? "forceBlack" : "forceWhite"}`}>
        {page.subtitle}
        {page.subtitle2 && (<><br />{page.subtitle2}</>)}
      </div>
      {/* <hr style={{ backgroundColor: page.textColor }} /> */}
      <div style={{ paddingTop: 18 }}>
        {page.content.services?.map((s: ServiceDescription, i: number) => (
          <div className="is-size-3">
            <strong>{s.title}</strong>
            <br />
            <ul>
              {s.list.map((i: { name: string, description: string }) => (
                <li className="tighten is-size-5"><strong>{i.name}</strong> : {i.description} </li>)
              )}
            </ul>
            {page.content.services && i < page.content.services.length - 1 && (
              separator
            )}
          </div>
        ))}
        {separator}
        {page.content.reviews?.length && (
          <>
            <div style={{ marginBottom: 20 }} className="is-size-3">
              <strong>Testimonials</strong>
            </div>
            <div className="testimonials">
              {page.content.reviews?.map((r: Review, i: number) => {
                const addBorder = page.content.reviews && i < page.content.reviews.length - 1
                return (
                  <div style={{ flex: 0.32, height: 150 }}>
                    <div>
                      <div className="is-size-5 is-italic tighten">
                        "{r.text}"
                        <p />
                        <span className="is-size-6"><strong>★★★★★  {` -${r.author}`}</strong></span>
                      </div>
                    </div>
                  </div>
                )
              }
              )}
            </div>
          </>
        )}
        <div className="logo-row">
          {/* {
            page.content.iconList?.map((icon) => (
              <div
                className="fv-social-icon"
                key={icon.name}
                onClick={() => window.open(icon.link)}
              >
                <i className={icon.name} />
              </div>
            ))
          } */}

        </div>
        {/* eSignatures form */}
        {/* <iframe
              style={{ width: "80%", height: "100vh" }}
              src="https://esignatures.io/sign/5e78dddd-bf85-4ddd-9853-a8b54b83d695?embedded=yes"
            /> */}
      </div>
    </div >
  )
}

export default Landing;