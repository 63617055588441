import React, { useEffect } from 'react'
import { PageProperties } from '../types'
import { getSentenceWithLinkPieces } from '../utils'

const Widget = ({ page }: PageProperties) => {
  const { sentenceWithLink } = page.content
  const [sentenceWithLinkPartOne, linkText, sentenceWithLinkPartTwo] = sentenceWithLink ? getSentenceWithLinkPieces(sentenceWithLink) : []

  return (
    <div className="view force-full-view"
      style={{
        height: '100%',
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className="title">
        {page.title}
      </div>
      <div className="subtitle">
        {page.subtitle}
      </div>
      <hr style={{ backgroundColor: 'black' }} />
      <span className="is-size-6 forceBlack">
        {sentenceWithLinkPartOne}
        <a
          className="is-italic underline forceBlack"
          rel="noopener noreferrer"
          href={sentenceWithLink?.link}
        >
          {linkText}
        </a>
        {sentenceWithLinkPartTwo}
      </span>
      {page.content.widget && <div dangerouslySetInnerHTML={{ __html: page.content.widget.html }} />}
    </div>
  )
}

export default Widget