import React from 'react'

import { PageProperties } from '../types'
import './ItemDetail.css'


const ItemDetail = ({ page }: PageProperties) => {
  const { sentenceWithLink } = page.content
  const pieces = sentenceWithLink?.text?.split(sentenceWithLink.linkText)
  const [sentenceWithLinkPartOne, sentenceWithLinkPartTwo] = pieces || []

  return (
    <div className="split-view force-full-view"
      style={{
        padding: '6%', background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className="resume has-text-centered">
        <a href={require(`../site/images/${page.content.pageImageLeft?.link}`)} target="_blank" rel="noreferrer">
          <img
            src={require(`../site/images/${page.content.pageImageLeft?.uri}`)}
            alt="resume"
          />
        </a>
        <div className="is-size-7">{page.content.pageImageLeft?.legend}</div>
      </div>
      <div className="description column is-three-fifths">
        <div className="title forceWhite">
          {page.title}
        </div>
        <div className="subtitle forceWhite">
          {page.subtitle}
        </div>
        <hr />
        {page.content.parragraph}
        <ul>
          {page.content.textList?.map((text) => <li key={text}>{text}</li>)}
        </ul>
        <br />
        {sentenceWithLinkPartOne}
        <span>&nbsp;</span>
        <a href="./contact" className="forceWhite is-italic underline">{sentenceWithLink?.linkText}</a>
        {sentenceWithLinkPartTwo}
      </div>
    </div>
  )
}

export default ItemDetail
