import React from 'react'
import { PageProperties } from '../types';
import './ItemList.css';

const TextOnly = ({ page }: PageProperties) => {
  const { sentenceWithLink, parragraph } = page.content
  const pieces = sentenceWithLink?.text?.split(sentenceWithLink.linkText)
  const [sentenceWithLinkPartOne, sentenceWithLinkPartTwo] = pieces || []

  return (
    <div className="view force-full-view"
      style={{
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <br />
      <br />
      <div className="center title forceWhite">
        {page.title}
      </div>
      <hr />
      <div className="largeFont forceWhite">
        {parragraph}
        <br />
        <br />
        {sentenceWithLinkPartOne}
        <a
          className="forceWhite is-italic underline"
          target="_blank"
          rel="noopener noreferrer"
          href={page.content.sentenceWithLink?.link}
        >
          {page.content.sentenceWithLink?.linkText}
        </a>
        {sentenceWithLinkPartTwo}
      </div>
    </div>
  )
}

export default TextOnly