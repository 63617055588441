import axios from 'axios'

const postReqHeaders = ({
    headers: {
        'Content-Type': 'application/json'
    }
})

/**
 * Execute the serverless edge function in file: ./netlify/edge-functions/send-message
 * @param obj
 * @returns response.data
 */
export const saveContactFormSubmission = async (obj: any) => {
    try {
        const requestUrl = "/send-message"
        const result: any = await axios.post(requestUrl, obj, postReqHeaders)
        const response = result.data
        return response
    } catch (error: any) {
        console.error(`ERROR in saveContactFormSubmission `, { obj, error }, error.toString())
    }
}

/**
 * Execute the serverless edge function in file: ./netlify/edge-functions/send-message
 * @param obj
 * @returns response.data
 */
export const saveStorageFormSubmission = async (obj: any) => {
    try {
        const requestUrl = "/send-storage-form"
        const result: any = await axios.post(requestUrl, obj, postReqHeaders)
        const response = result.data
        return response
    } catch (error: any) {
        console.error(`ERROR in saveStorageFormSubmission `, { obj, error }, error.toString())
    }
}

/**
 * Execute the serverless edge function in file: ./netlify/edge-functions/parse-failed-transaction-email
 * @param obj
 * @returns response.data
 */
export const parseFailedTransactionEmail = async (obj: any) => {
    try {
        const requestUrl = "/parse-failed-transaction-email"
        const result: any = await axios.post(requestUrl, obj, postReqHeaders)
        const response = result.data
        return response
    } catch (error: any) {
        console.error(`ERROR in parseFailedTransactionEmail `, { obj, error }, error.toString())
    }
}