import React, { useCallback, useEffect, useState } from 'react'
// import DatePicker from "react-datepicker";
import { withFormik } from 'formik'
import * as Yup from 'yup'
import './StorageForm.css'
import { PageProperties } from '../../types'
import { getFieldMapForList } from '../../clickup-utils'
import website from '../../site/config'
import { saveStorageFormSubmission } from '../../api-utils'
import PocketBase from 'pocketbase';
import { separator } from '../../App'
import { OAuthUser } from '../../site/pbTypes'
import { US_STATES } from '../../site/site-constants'

const pb = new PocketBase('https://api.gahannarvandboat.com');

interface StorageFormValues {
  email: string,
  password?: string,
  passwordConfirm?: string,
  firstName: string,
  lastName: string,
  businessName: string,
  phone: string,
  other_phone: string,
  other_email: string,
  address_line_1: string,
  address_line_2: string,
  city: string,
  state: string,
  zip: string,
  vehicleType: string,
  vehicleYear: string,
  vehicleMake: string,
  vehicleModel: string,
  vehicleLicState: string,
  vehicleLicNumber: string,
  vinNumber: string,
}

const StorageForm = ({ page }: PageProperties) => {
  const [messageSent, setMessageSent] = useState(false)
  const [storageTableFieldMap, setStorageTableFieldMap] = useState(undefined);
  const queryStr = window.location.search
  const urlParams = new URLSearchParams(queryStr)
  const spaceId = urlParams.get('id')

  const [oauthUser, setOauthUser] = useState<OAuthUser | undefined>();
  const [token, setToken] = useState<string | undefined>();

  console.log(">> US STATES",
    Object.keys(US_STATES).map((stCode: string) => US_STATES[stCode])
  )

  useEffect(() => {
    if (!storageTableFieldMap) {
      getFieldMapForList(process.env.REACT_APP_CLICKUP_STORAGE_FORM_SUBMISSION_LIST_ID!)
        .then((res: any) => {
          setStorageTableFieldMap(res);
        })
    }
  }, [storageTableFieldMap])

  const innerForm = ({
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  }: any) => {

    // console.log(">> VALUES FOMRS", values);

    const createUserWithGoogle = async () => {
      // This method initializes a one-off realtime subscription and will
      // open a popup window with the OAuth2 vendor page to authenticate.
      //
      // Once the external OAuth2 sign-in/sign-up flow is completed, the popup
      // window will be automatically closed and the OAuth2 data sent back
      // to the user through the previously established realtime connection.
      //
      // If the popup is being blocked on Safari, you can try the suggestion from:
      // https://github.com/pocketbase/pocketbase/discussions/2429#discussioncomment-5943061.
      const authData = await pb.collection('customers').authWithOAuth2({ provider: 'google' })
      if (authData) {
        console.log(">> GOT authData : ", authData);
        setOauthUser({
          rawUser: authData.meta?.rawUser,
          recordId: authData.record.id,
        })
        setToken(pb.authStore.token)
      }
      // console.log(">> Email ", authData?.meta?.rawUser.email);
      // console.log(">> First ", authData?.meta?.rawUser.given_name);
      // console.log(">> Last ", authData?.meta?.rawUser.family_name);

      // after the above you can also access the auth data from the authStore
      console.log(">> pb.authStore.isValid", pb.authStore.isValid);
      console.log(">> pb.authStore.token", pb.authStore.token);
      // pb.authStore.clear();

      // username: `${oauthUser.rawUser.given_name}-${oauthUser.rawUser.family_name}-${values.phone.slice(values.phone.length - 4, values.phone.length)}`.toLowerCase(),
      await pb.collection('customers').update(authData.record.id, { status: "incomplete" });
      return authData
    }



    return (
      <form onSubmit={handleSubmit}>
        <div className="userLogin">
          {!oauthUser ? (
            <>
              <div className="column is-one-third">
                <label htmlFor="email" className="form-label forceBlack" >
                  Email
                </label>
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email ? 'text-input error' : 'text-input'}
                />
                {errors.email
                  && touched.email && <div className="input-feedback">{errors.email}</div>}
                <p style={{ height: 24 }} />
                <label htmlFor="password" className="form-label forceBlack" >
                  Password
                </label>
                <input
                  id="password"
                  placeholder="Enter your password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.password && touched.password ? 'text-input error' : 'text-input'}
                />
                {errors.password
                  && touched.password && <div className="input-feedback">{errors.password}</div>}
                <p style={{ height: 24 }} />
                <label htmlFor="passwordConfirm" className="form-label forceBlack" >
                  Confirm Password
                </label>
                <input
                  id="passwordConfirm"
                  placeholder="Enter your password"
                  type="password"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.passwordConfirm && touched.passwordConfirm ? 'text-input error' : 'text-input'}
                />
                {errors.passwordConfirm
                  && touched.passwordConfirm && <div className="input-feedback">{errors.passwordConfirm}</div>}
              </div>
              <div className="oauth-group">
                <label className="form-label forceBlack" style={{ textAlign: 'center', fontWeight: 400 }}>
                  Or
                </label>
                <div onClick={() => createUserWithGoogle()}>
                  <img
                    style={{ width: 189, height: 40 }}
                    src={require(`../../site/images/Google_Auth_Continue.png`)}
                    alt="Continue with Google"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="column is-two-thirds">
              <div className="is-size-6 forceBlack" style={{ display: "flex", flexDirection: "row", gap: 8, flexWrap: 'wrap' }}>
                <img
                  style={{ width: 30, height: 30 }}
                  src={require(`../../site/images/Google_Authed_G.png`)}
                  alt="Continue with Google"
                />
                <strong>{oauthUser.rawUser.email}</strong>
              </div>
            </div>
          )}
        </div>
        {!oauthUser && separator}
        <div className="allInputs">
          <div className="column is-one-third">
            <label htmlFor="firstName" className="forceBlack" >
              First Name
            </label>
            <input
              id="firstName"
              placeholder="Enter your first name"
              type="text"
              value={oauthUser?.rawUser.given_name || values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
            />
            {errors.firstName
              && touched.firstName && <div className="input-feedback">{errors.firstName}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="lastName" className="forceBlack" >
              Last Name
            </label>
            <input
              id="lastName"
              placeholder="Enter your last name"
              type="text"
              value={oauthUser?.rawUser.family_name || values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
            />
            {errors.lastName
              && touched.lastName && <div className="input-feedback">{errors.lastName}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="phone" className="forceBlack" >
              Phone
            </label>
            <input
              id="phone"
              placeholder="Enter your phone"
              type="text"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.phone && touched.phone ? 'text-input error' : 'text-input'}
            />
            {errors.phone
              && touched.phone && <div className="input-feedback">{errors.phone}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="phone" className="forceBlack" >
              Other Phone
            </label>
            <input
              id="phone2"
              placeholder="(optional)"
              type="text"
              value={values.phone2}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.phone2 && touched.phone2 ? 'text-input error' : 'text-input'}
            />
            {errors.phone2
              && touched.phone2 && <div className="input-feedback">{errors.phone2}</div>}
          </div>

          <div className="column is-one-third">
            <label htmlFor="address_line_1" className="forceBlack" >
              Address
            </label>
            <input
              id="address_line_1"
              placeholder="Address Line"
              type="text"
              value={values.address_line_1}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.address_line_1 && touched.address_line_1 ? 'text-input error' : 'text-input'}
            />
            {errors.address_line_1
              && touched.address_line_1 && <div className="input-feedback">{errors.address_line_1}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="address_line_2" className="forceBlack" >
              apt / suite
            </label>
            <input
              id="address_line_2"
              placeholder="Apt or Suite #"
              type="text"
              value={values.address_line_2}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.address_line_2 && touched.address_line_2 ? 'text-input error' : 'text-input'}
            />

            <p style={{ height: 24 }} />
            <label htmlFor="city" className="forceBlack" >
              City
            </label>
            <input
              id="city"
              placeholder="Enter your last name"
              type="text"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.city && touched.city ? 'text-input error' : 'text-input'}
            />
            {errors.city
              && touched.city && <div className="input-feedback">{errors.city}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="state" className="forceBlack" >
              State and Zipcode
            </label>
            <div className="state-and-something">
              <select
                id="state"
                style={{ width: 100, height: 36, borderRadius: 4 }}
                onChange={handleChange}
              >
                <option value="--">-State-</option>
                {Object.keys(US_STATES).map((stCode: string) => (
                  <option value={stCode}>{US_STATES[stCode].toString()}</option>
                ))}
                <option value="travel-trailer">Travel Trailer</option>
              </select>
              {errors.state
                && touched.state && <div className="input-feedback">{errors.state}</div>}

              <input
                id="zip"
                placeholder="Zip code"
                type="text"
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`input-after-state ${errors.zip && touched.zip ? 'text-input error' : 'text-input'}`}
              />
              {errors.zip
                && touched.zip && <div className="input-feedback">{errors.zip}</div>}
            </div>
          </div>
        </div>

        <p style={{ height: 24 }} />
        {separator}

        <div className="allInputs">
          {/* vehicle Type, Year and Move-in Date */}
          <div className="column is-one-third">
            <label className="forceBlack" htmlFor="vehicleType" >
              Vehicle Type
            </label>
            <select
              id="vehicleType"
              style={{ width: "80%", height: 36, borderRadius: 4 }}
              onChange={handleChange}
            >
              <option value="--">-Select a Vehicle Type-</option>
              <option value="travel-trailer">Travel Trailer</option>
              <option value="camping-trailer">Camping Trailer</option>
              <option value="utility-trailer">Utility Trailer</option>
              <option value="enclosed-trailer">Enclosed Trailer</option>
              <option value="motorhome">Motorhome</option>
              <option value="5th-wheel">5th Wheel</option>
              <option value="pop-up">Pop-up Camper</option>
              <option value="boat">Boat</option>
              <option value="van">Van</option>
              <option value="car">Car</option>
              <option value="pickup">Pickup</option>
              <option value="suv">SUV</option>
              <option value="other">Other</option>
            </select>

            <p style={{ height: 24 }} />
            <label className="forceBlack" htmlFor="vehicleYear" >
              Vehicle Year
            </label>
            <select
              id="vehicleYear"
              style={{ width: "80%", height: 36, borderRadius: 4 }}
              onChange={handleChange}
            >
              <option key="--" value="--">-Vehicle Year-</option>
              {Array.from({ length: 25 }, (value, index) => {
                const yearValue = (new Date()).getFullYear() - index;
                return (
                  <option key={yearValue} value={yearValue}>{yearValue}</option>
                )
              })}
            </select>

            <p style={{ height: 24 }} />
            <label htmlFor="vehicleLicState" className="forceBlack" >
              State and License Plate
            </label>
            <div className="state-and-something">
              <select
                id="vehicleLicState"
                style={{ width: 100, height: 36, borderRadius: 4 }}
                onChange={handleChange}
              >
                <option value="--">-State-</option>
                {Object.keys(US_STATES).map((stCode: string) => (
                  <option value={stCode}>{US_STATES[stCode]}</option>
                ))}
                <option value="travel-trailer">Travel Trailer</option>
              </select>
              {errors.vehicleLicState
                && touched.vehicleLicState && <div className="input-feedback">{errors.vehicleLicState}</div>}

              <input
                id="vehicleLicNumber"
                placeholder="License Plate"
                type="text"
                value={values.vehicleLicNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`input-after-state ${errors.vehicleLicNumber && touched.vehicleLicNumber ? 'text-input error' : 'text-input'}`}
              />
              {errors.vehicleLicNumber
                && touched.vehicleLicNumber && <div className="input-feedback">{errors.vehicleLicNumber}</div>}
            </div>
          </div>

          <div className="column is-one-third">
            <label htmlFor="vinNumber" className="forceBlack" >
              Vin Number
            </label>
            <input
              id="vinNumber"
              placeholder="Vehicle VIN #"
              type="text"
              value={values.vinNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.vinNumber && touched.vinNumber ? 'text-input error' : 'text-input'}
            />
            {errors.vinNumber
              && touched.vinNumber && <div className="input-feedback">{errors.vinNumber}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="vehicleMake" className="forceBlack" >
              Make
            </label>
            <input
              id="vehicleMake"
              type="text"
              placeholder="Vehicle Make"
              value={values.vehicleMake}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.vehicleMake && touched.vehicleMake ? 'text-input error' : 'text-input'}
            />
            {errors.vehicleMake
              && touched.vehicleMake && <div className="input-feedback">{errors.vehicleMake}</div>}

            <p style={{ height: 24 }} />
            <label htmlFor="vehicleModel" className="forceBlack" >
              Model
            </label>
            <input
              id="vehicleModel"
              placeholder="Vehicle Model"
              type="text"
              value={values.vehicleModel}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.vehicleModel && touched.vehicleModel ? 'text-input error' : 'text-input'}
            />
            {errors.vehicleModel
              && touched.vehicleModel && <div className="input-feedback">{errors.vehicleModel}</div>}
          </div>
        </div>

        <p style={{ height: 24 }} />
        {separator}
        <button
          type="button"
          className="outline"
          onClick={handleReset}
          disabled={!dirty || isSubmitting}
          style={{ background: !dirty || isSubmitting ? 'grey' : 'transparent', color: 'black', border: '2px solid black' }}
        >
          <span style={{ fontWeight: 700, letterSpacing: 1.2 }}>Reset</span>
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          style={{ background: 'black', color: 'yellow' }}
        >
          <span style={{ fontWeight: 700, letterSpacing: 1.2 }}>Submit</span>
        </button>
      </form >
    )
  }

  const saveMessage = useCallback(async (message: StorageFormValues) => {
    if (!storageTableFieldMap) {
      console.log(">> GOT NOOOO STORAGE FIELD MAP")
      return
    }

    console.log(">> MESSG", storageTableFieldMap)
    console.log(">> MESSG", message)
    console.log(">> process.env.REACT_APP_CLICKUP_BASE_API_URL", process.env.REACT_APP_CLICKUP_BASE_API_URL)
    console.log(">> process.env.REACT_APP_CLICKUP_PAT", process.env.REACT_APP_CLICKUP_PAT)
    console.log(">> process.env.REACT_APP_CLICKUP_STORAGE_FORM_SUBMISSION_LIST_ID", process.env.REACT_APP_CLICKUP_STORAGE_FORM_SUBMISSION_LIST_ID)
    // const messageToSend = {
    //   name: message.name,
    //   email: message.email,
    //   projecturl: message.url,
    //   message: message.note,
    //   senton: Date.now(),
    //   sentfrom: `https://${website.config.domain}`
    // }
    // const messageToSend = {
    //   name: `${message.firstName} ${message.lastName}`,
    //   ...message
    // }
    // const apiRes = await saveStorageFormSubmission({ message: messageToSend, tableFieldMap: storageTableFieldMap })

    // if (apiRes) {
    //   setMessageSent(true);
    // }
  }, [storageTableFieldMap])

  const createUser = async (values: StorageFormValues) => {
    if (!oauthUser?.recordId) {
      // This method initializes a one-off realtime subscription and will
      // open a popup window with the OAuth2 vendor page to authenticate.
      //
      // Once the external OAuth2 sign-in/sign-up flow is completed, the popup
      // window will be automatically closed and the OAuth2 data sent back
      // to the user through the previously established realtime connection.
      //
      // If the popup is being blocked on Safari, you can try the suggestion from:
      // https://github.com/pocketbase/pocketbase/discussions/2429#discussioncomment-5943061.
      const authData = await pb.collection('customers').create({
        status: "incomplete",
        username: `${values.firstName}-${values.lastName}-${values.phone.slice(values.phone.length - 4, values.phone.length)}`.toLowerCase(),
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        city: values.city,
        state: values.state,
        zip: values.zip,
        phone: values.phone,
        other_phone: values.other_phone,
        other_email: values.other_email,
      });

      console.log(">> AUTH DATA", authData);

      // after the above you can also access the auth data from the authStore
      console.log(">> pb.authStore.isValid", pb.authStore.isValid);
      console.log(">> pb.authStore.token", pb.authStore.token);
      // console.log("pb.authStore.model.id");

      // "logout" the last authenticated model
      // pb.authStore.clear();

      return authData
    }

    // else we created a user already
    console.log(">> UPDATING USER ", oauthUser.recordId);
    const updatedData = await pb.collection('customers').update(oauthUser.recordId, {
      username: `${oauthUser.rawUser.given_name}-${oauthUser.rawUser.family_name}-${values.phone.slice(values.phone.length - 4, values.phone.length)}`.toLowerCase(),
      first_name: oauthUser.rawUser.given_name,
      last_name: oauthUser.rawUser.family_name,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      other_phone: values.other_phone,
      other_email: values.other_email
    })
    console.log(">> Updated record", updatedData);
    return updatedData
  }

  const createVehicle = async (userEmail: string, values: StorageFormValues) => {
    if (userEmail) {
      const vehicle = {
        customerId: userEmail,
        type: values.vehicleType,
        year: values.vehicleYear,
        make: values.vehicleMake,
        model: values.vehicleModel,
        lic_plate_state: values.vehicleLicState,
        lic_plate_number: values.vehicleLicNumber,
        vin: values.vinNumber
      }

      console.log(">> SENDING CREATE VEHICLE ", vehicle);
      const vehicleData = await pb.collection('vehicles').create(vehicle);

      console.log(">> VEHICLE DATA", vehicleData);
      return vehicleData;
    }
  }

  const EnhancedForm = withFormik({

    mapPropsToValues: () => ({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      other_phone: '',
      other_email: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip: '',
      businessName: '',
      vehicleType: '',
      vehicleYear: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleLicState: '',
      vehicleLicNumber: '',
      vinNumber: '',
      // insuranceCo: '',
      // insurancePolicyId: '',
      // insurancePhone: '',
    }),

    // validationSchema: Yup.object().shape({
    //   firstName: Yup.string().required('*First Name is required'),
    //   lastName: Yup.string().required('*Last Name is required'),
    //   phone: Yup.string().required('*Phone is required'),
    //   email: Yup.string().email('*Invalid email address').required('*Email is required'),
    //   vehicleType: Yup.string().required('*Vehicle Type is required'),
    //   vehicleYear: Yup.string().required('*Vehicle Year is required'),
    //   vehicleYear: Yup.string().required('*Vehicle Length is required'),
    // }),

    handleSubmit: (values, { props, setSubmitting }) => {
      setTimeout(async () => {
        // saveMessage(values)
        const userResponse: any = await createUser(values);
        if (userResponse) {
          const vehicleResponse = await createVehicle(userResponse.id, values);
          if (vehicleResponse) {
            setMessageSent(true);
          }
        }
        setSubmitting(false)
      }, 1000)
    },
    displayName: 'BasicForm', // helps with React DevTools
  })(innerForm)

  console.log(">> Space ID: ", spaceId)

  return (
    <div className="view column"
      style={{
        padding: "8%",
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className={`title ${page.textColor ? "" : "forceWhite"}`}>
        {messageSent
          ? (
            <div>
              <span className="huge-icon" role="img" aria-label="img">&#9989;&nbsp;</span>
              Thanks!
            </div>
          )
          : page.title}
      </div>
      <div className="forceBlack subtitle">
        {
          messageSent ? 'Your message was received!' : page.subtitle
        }
        {separator}
      </div>
      {
        messageSent
          ? (
            <a
              className={`underline ${page.textColor ? "" : "forceWhite"}`}
              href="/"
            >&#127968; Back to Home</a>
          ) : <EnhancedForm />
      }
    </div >
  )
}

export default StorageForm
