import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ItemList.css'
import { ItemDetail, PageProperties } from '../types'
import PocketBase from 'pocketbase'
import { useState } from 'react'
import { useEffect } from 'react'
import { formatDate } from '../utils'
import { separator } from '../App'

// const separator = <hr style={{ background: "black", height: 1.5 }} />
const pb = new PocketBase('https://api.gahannarvandboat.com');

const getItemList = (items: Array<any>, navigate: (id: string) => void) => (
  <div className="thin">
    {
      items?.map((item, i) => {
        // const imageDiv = (<div className="project-img has-text-centered  is-flex-direction-column is-align-items-center is-align-self-center">
        //   <div className="is-size-7 is-align-items-center is-align-self-center">{item.image?.legend}</div>
        //   <img
        //     className="project-img-file"
        //     width={item.image?.width ? item.image.width : "600"}
        //     alt={item.title}
        //     src={require(`../site/images/${item.image?.uri}`)}
        //     onClick={() => window.open(item.image?.link)}
        //   />
        // </div>)

        const descriptionDiv = (<div className="project-desc">
          <div className="forceBlack is-size-4 is-italic">
            <div><strong style={{ fontFamily: "monospace" }}>{`Available ${item.status === "vacant" ? "Today" : "on " + formatDate(new Date(item.move_out_date))}`}</strong></div>
            <span className="is-capitalized" style={{ fontFamily: "monospace" }}>{item.type} </span><span style={{ fontFamily: "monospace" }}>space</span>
            <div className="is-size-5" style={{ fontFamily: "monospace" }}>{`${item.width}ft x ${item.length}ft`}</div>
            <div>
              <strong style={{ fontFamily: "monospace" }}>{`$${item.monthly_rate}.00 Monthly`}</strong>
            </div>
          </div>
        </div>)

        return (
          <div className="project" key={item.title}>
            {/* {imageDiv} */}
            {descriptionDiv}
            {<hr className="bigHR" />}
            <br />
            <div>
              <button
                style={{ background: 'black', color: 'yellow' }}
                onClick={() => {
                  console.log("Reserving spot ", item.id)
                  navigate(`/storage-form?id=${item.id}`)
                }}
              >
                <div className="is-size-5" style={{ fontFamily: "monospace" }}>Reserve Now</div>
              </button>
            </div>
          </div>
        )
      })
    }
  </div >
)

const ItemList = ({ page }: PageProperties) => {
  const [items, setItems] = useState<any[]>([])
  const navigate = useNavigate();

  useEffect(() => {
    if (items && items.length === 0) {
      const fetchData = async () => {
        const data = await pb.collection('spaces').getList(undefined, undefined, {
          filter: 'status = "vacant" ||  status = "move-out-scheduled"',
          sort: 'monthly_rate'
        });
        if (data.items.length > 0) {
          setItems(data.items);
        }
      }

      fetchData()
        .catch((error: Error) => {
          console.error(" Oh NO!", error);
        })
    }
  }, [items, setItems])




  return (
    <div className="view force-full-view"
      style={{
        height: '100%', padding: '6%',
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className="title forceBlack is-size-1">
        {page.title}
      </div>
      <div className="subtitle forceBlack is-size-3">
        {page.subtitle}
      </div>
      {separator}
      {items && getItemList(items, navigate)}
    </div>
  )
}

export default ItemList