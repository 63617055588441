import React, { useCallback, useEffect, useState } from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import './ContactForm.css'
import { PageProperties } from '../../types'
import { getFieldMapForList } from '../../clickup-utils'
import website from '../../site/config'
import { saveContactFormSubmission } from '../../api-utils'

interface FormValues {
  email: string
  name: string
  url: string
  note: string
}

const innerForm = ({
  values,
  touched,
  errors,
  dirty,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
}: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="allInputs">
        <div className="column is-one-third">
          <label htmlFor="name" className="forceBlack" style={{ display: 'block', fontWeight: 100 }}>
            Name
          </label>
          <input
            id="name"
            placeholder="Enter your name"
            type="text"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.name && touched.name ? 'text-input error' : 'text-input'}
          />
          {errors.name
            && touched.name && <div className="input-feedback">{errors.name}</div>}

          <label htmlFor="email" className="forceBlack" style={{ display: 'block', fontWeight: 100 }}>
            Email
          </label>
          <input
            id="email"
            placeholder="Enter your email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
          />
          {errors.email
            && touched.email && <div className="input-feedback">{errors.email}</div>}

          <label className="forceBlack" htmlFor="url" style={{ display: 'block', fontWeight: 100 }}>
            Project URL
          </label>
          <input
            id="url"
            placeholder="http://yourproject.com"
            type="text"
            value={values.url}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.url && touched.url ? 'text-input error' : 'text-input'}
          />
          {errors.url
            && touched.url && <div className="input-feedback">{errors.url}</div>}
        </div>

        <div className="column is-two-thirds">
          <label htmlFor="note" className="forceBlack" style={{ display: 'block', fontWeight: 100 }}>
            Message
          </label>
          <textarea
            id="note"
            placeholder="Your message"
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.note && touched.note ? 'text-input error' : 'text-input'}
          />
          {errors.note
            && touched.note && <div className="input-feedback">{errors.note}</div>}
        </div>
      </div>

      <br />
      <hr style={{ backgroundColor: 'black' }} />
      <button
        type="button"
        className="outline"
        onClick={handleReset}
        disabled={!dirty || isSubmitting}
      >
        Reset
      </button>
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </form>
  )
}

const ContactForm = ({ page }: PageProperties) => {
  const [messageSent, setMessageSent] = useState(false)
  const [contactTableFieldMap, setContactTableFieldMap] = useState(undefined);

  useEffect(() => {
    if (!contactTableFieldMap) {
      getFieldMapForList(process.env.REACT_APP_CONTACT_FORM_SUBMISSION_LIST_ID!)
        .then((res: any) => {
          setContactTableFieldMap(res);
        })
    }
  }, [contactTableFieldMap])

  const saveMessage = useCallback(async (message: FormValues) => {
    if (!contactTableFieldMap) {
      return
    }

    const messageToSend = {
      name: message.name,
      email: message.email,
      projecturl: message.url,
      message: message.note,
      senton: Date.now(),
      sentfrom: `https://${website.config.domain}`
    }
    const apiRes = await saveContactFormSubmission({ messageToSend, contactTableFieldMap })
    if (apiRes.length > 0) {
      setMessageSent(true);
    }
  }, [contactTableFieldMap])

  const EnhancedForm = withFormik({

    mapPropsToValues: () => ({
      email: '',
      name: '',
      url: '',
      note: '',
    }),

    validationSchema: Yup.object().shape({
      name: Yup.string().required('*Name is required'),
      email: Yup.string().email('*Invalid email address').required('*Email is required'),
      url: Yup.string().url('*Invalid URL'),
      note: Yup.string().required('*Please enter a message'),
    }),

    handleSubmit: (values, { props, setSubmitting }) => {
      setTimeout(() => {
        saveMessage(values)
        setSubmitting(false)
      }, 1000)
    },
    displayName: 'BasicForm', // helps with React DevTools
  })(innerForm)

  return (
    <div className="view column force-full-view"
      style={{
        padding: '6%',
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className={`title ${page.textColor ? "" : "forceWhite"}`}>
        {messageSent
          ? (
            <div>
              <span className="huge-icon" role="img" aria-label="img">&#9989;&nbsp;</span>
              Thanks!
            </div>
          )
          : page.title}
      </div>
      <div className="forceBlack">
        {
          messageSent ? 'Your message was received!' : page.subtitle
        }
        <hr style={{ backgroundColor: 'black' }} />
      </div>
      {
        messageSent
          ? (
            <a
              className={`underline ${page.textColor ? "" : "forceWhite"}`}
              href="/"
            >&#127968; Back to Home</a>
          ) : <EnhancedForm />
      }
    </div>
  )
}

export default ContactForm
